import type { ReactElement } from "react";
import React from "react";

import { TableLink, ToolTip } from "@commonComponents";
import { styled } from "styled-components";

import { colorTheme, gridSpacing } from "@utils";

type HeaderProps = {
  title: string;
  subtitle?: string | ReactElement;
  toolTip?: string;
  trendDirection?: "up" | "down";
  trendPercentage?: number;
  trendToolTip?: string;
  linkUrl?: string;
  linkText?: string;
  style?: React.CSSProperties;
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${gridSpacing[5]}px;
  align-items: center;

  p {
    color: ${colorTheme("neutral")};
    font-weight: 500;
    font-size: 16px;
  }
`;

const Header = ({
  title,
  subtitle,
  toolTip,
  linkUrl,
  linkText,
  trendDirection,
  trendPercentage,
  trendToolTip,
  style,
}: HeaderProps) => {
  const renderTrend = () => {
    if (!trendDirection || !trendPercentage) return null;

    const context = (
      <div
        style={{
          color:
            trendDirection === "up"
              ? colorTheme("success")
              : colorTheme("danger"),
        }}
      >
        <i className={`fa-regular fa-arrow-${trendDirection}-right`} />
        {trendPercentage}%
      </div>
    );

    if (trendToolTip) {
      return <ToolTip text={trendToolTip}>{context}</ToolTip>;
    }

    return context;
  };

  return (
    <HeaderContainer style={style}>
      <div>
        <p style={{ display: "flex" }}>
          {title}{" "}
          {toolTip && (
            <ToolTip text={toolTip}>
              <i
                style={{
                  color: colorTheme("neutralL2"),
                  marginLeft: gridSpacing[1],
                }}
                className="fa-regular fa-info-circle"
              />
            </ToolTip>
          )}
        </p>
        {subtitle && (
          <p style={{ color: colorTheme("neutralL2"), fontSize: 13 }}>
            {subtitle}
          </p>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {linkUrl && linkText && (
          <TableLink url={linkUrl}>
            {linkText}
            <i
              className="fa-regular fa-arrow-right"
              style={{ marginLeft: gridSpacing[1] }}
            />
          </TableLink>
        )}
        {renderTrend()}
      </div>
    </HeaderContainer>
  );
};

export default Header;

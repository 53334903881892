import React from "react";

import { Button, TableLink, ToolTip } from "@commonComponents";
import { connect } from "react-redux";

import { colorTheme } from "@utils";

export enum DocumentationType {
  Lucerna,
  Customer,
}

type DocumentationButtonProps = {
  documentationUrl?: string;
  customerDocumentationUrl?: string;
  docRoute: string;
  type?: DocumentationType;
};

const DocumentationButton = ({
  documentationUrl,
  customerDocumentationUrl,
  docRoute,
  type = DocumentationType.Lucerna,
}: DocumentationButtonProps) => {
  const docUrl =
    type === DocumentationType.Customer
      ? customerDocumentationUrl
      : documentationUrl;
  if (!docUrl) return null;

  return (
    <TableLink newTab url={`${docUrl}${docRoute}`}>
      <ToolTip text="User Guide">
        <Button
          type="link"
          style={{
            color: colorTheme("neutralL2"),
          }}
          icon="fa-regular fa-book"
        />
      </ToolTip>
    </TableLink>
  );
};

const mapStateToProps = (state: {
  main: {
    documentationUrl?: string;
    customerDocumentationUrl?: string;
  };
}) => {
  return {
    documentationUrl: state.main.documentationUrl,
    customerDocumentationUrl: state.main.customerDocumentationUrl,
  };
};

export default connect(mapStateToProps)(DocumentationButton);

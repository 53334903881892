import defaultColorTheme from "../utils/defaultColorTheme";

const initialState = {
  testMode: null,
  testUserServiceKey: null,
  colorTheme: defaultColorTheme,
  logoUrl: "/assets/images/logoColored.png",
  apiConfig: {},
  documentationUrl: "",
  customerDocumentationUrl: "",
  user: {
    metadata: {
      lastVisitedPages: [],
      insightPortalFavorites: [],
      filesMonitoringGraphIsOpen: false,
      insightPortalRecent: [],
      insightsTab: "",
      sideNavHiddenSections: [],
      sideNavWidth: 300,
      usersPageSize: 10,
      recodingRulesTab: "",
      careTeamPageSize: 10,
      companiesPageSize: 10,
      providersPageSize: 10,
      facilitiesPageSize: 10,
      healthPlansPageSize: 10,
      organizationsPageSize: 10,
      orchestrationRunHistory: 10,
    },
    uuid: "",
    is_superuser: false,
  },
  windowWidth: 1800,
  confirmationModal: {
    opened: false,
  },
  locks: {
    isFetching: true,
    isRefreshing: false,
    error: false,
    result: [],
  },
  advancedFilters: {
    refresh: false,
  },
  application: "InsightsPortal",
  searchFilters: {
    isFetching: true,
    error: false,
    result: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "MAIN_SET_USER": {
      return Object.assign({}, state, {
        user: action.payload.user,
      });
    }

    case "MAIN_SET_TEST_MODE": {
      return Object.assign({}, state, {
        testMode: true,
        testUserServiceKey: action.payload.userServiceKey,
        testUserEmail: action.payload.userEmail,
      });
    }

    case "MAIN_SET_LOGO": {
      return Object.assign({}, state, {
        logoUrl: action.payload.logoUrl,
      });
    }

    case "MAIN_SET_LAST_SEARCH": {
      return Object.assign({}, state, {
        lastSearch: action.payload.lastSearch,
      });
    }

    case "MAIN_SET_IMPORT_EXPORT": {
      return Object.assign({}, state, {
        importExport: {
          activeAction: action.payload.activeAction,
          data: action.payload.data,
          name: action.payload.name,
        },
      });
    }

    case "MAIN_SET_TABLE_EXPORT": {
      return Object.assign({}, state, {
        tableExport: action.payload,
      });
    }

    case "MAIN_SET_ADVANCED_FILTER_REFRESH": {
      return Object.assign({}, state, {
        advancedFilters: {
          ...state?.advancedFilters,
          refresh: action.payload.refresh,
        },
      });
    }

    case "MAIN_SET_WINDOW_WIDTH": {
      return Object.assign({}, state, {
        windowWidth: action.payload.width,
      });
    }

    case "MAIN_THEME_SET_ALL": {
      return Object.assign({}, state, {
        colorTheme: action.payload.theme,
      });
    }

    case "MAIN_SET_API_CONFIG": {
      return Object.assign({}, state, {
        apiConfig: action.payload.apiConfig,
      });
    }

    case "MAIN_SET_DOCUMENTATION_URL": {
      return Object.assign({}, state, {
        documentationUrl: action.payload.documentationUrl,
      });
    }

    case "MAIN_SET_CUSTOMER_DOCUMENTATION_URL": {
      return Object.assign({}, state, {
        customerDocumentationUrl: action.payload.customerDocumentationUrl,
      });
    }

    case "MAIN_SET_CONFIRMATION_MODAL": {
      return Object.assign({}, state, {
        ...state,
        confirmationModal: {
          ...action.payload,
          callback: action.payload.callback
            ? action.payload.callback.toString().replace(/(\r\n|\n|\r)/gm, "")
            : null,
          opened: true,
        },
      });
    }

    case "MAIN_CLOSE_CONFIRMATION_MODAL": {
      return Object.assign({}, state, {
        ...state,
        confirmationModal: {
          opened: false,
        },
      });
    }

    case "MAIN_SET_APPLICATION": {
      return Object.assign({}, state, {
        ...state,
        application: action.payload.application,
      });
    }

    case "MAIN_SET_ROUTES": {
      return Object.assign({}, state, {
        ...state,
        routes: action.payload.routes,
      });
    }

    case "MAIN_FETCH_LOCKS": {
      let result = action.payload.result;
      if (action.payload.isRefreshing) {
        result = state.locks.result;
      }
      return Object.assign({}, state, {
        locks: { ...action.payload, result },
      });
    }

    case "MAIN_FETCH_SEARCH_FILTER": {
      return Object.assign({}, state, {
        ...state,
        searchFilters: action.payload,
      });
    }

    case "MAIN_SET_SEARCH_FILTER": {
      return Object.assign({}, state, {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          result: action.payload,
        },
      });
    }

    default:
      return state;
  }
}

import axios from "axios";
import type { AxiosCacheInstance } from "axios-cache-interceptor";
import { setupCache, buildWebStorage } from "axios-cache-interceptor";

const instance = axios.create();

type CachedAxiosProps = AxiosCacheInstance & {
  clearCache: (url?: string) => void;
};

/**
 * Use axios with caching for 8 hours
 */
const cachedAxios = setupCache(instance, {
  ttl: 8 * 60 * 60 * 1000, // 8 hours
  generateKey: (config) => `cache-${config.url}`,
  storage: buildWebStorage(localStorage, "axios-cache:"),
}) as CachedAxiosProps;

/**
 * Clear cached data for a specific URL or all cached data.
 *
 * @param url The URL to clear from the cache. If not provided, all cached data will be cleared.
 */
const clearCache = async (url?: string) => {
  if (cachedAxios.storage?.clear) {
    if (url) {
      await cachedAxios.storage.remove(`cache-${url}`);
      return;
    }

    await cachedAxios.storage.clear();
  } else {
    console.error("Cache storage is not available.");
  }
};

cachedAxios.clearCache = clearCache;

export default cachedAxios;

import React from "react";

import styled, { keyframes, css } from "styled-components";

import ToolTip from "../ToolTip/ToolTip";
import type { MarginInterface } from "../utilities/interfaces";
import { getMargins } from "../utilities/margin";
import { colorTheme } from "~/src/utils";

interface MetricBadgeProps extends MarginInterface {
  value: number;
  percentage?: boolean;
  color?: string;
  getColor?: (value: number) => string;
  loading?: boolean;
  tooltip?: string;
}

const pulse = keyframes`
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
`;

const Badge = styled.div<{
  $backgroundColor: string;
  $loading: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: ${colorTheme("white")};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  min-width: 48px;
  max-width: 48px;
  ${({ $loading }) =>
    $loading &&
    css`
      animation: ${pulse} 1.5s ease-in-out infinite;
    `}
`;

const BadgeWrapper = styled.div`
  display: inline-block;
`;

const MetricBadge = ({
  value,
  percentage = false,
  color = colorTheme("neutralL3"),
  getColor,
  loading = false,
  tooltip,
  m,
  mx,
  my,
  mt,
  mb,
  mr,
  ml,
}: MetricBadgeProps) => {
  const displayValue = percentage ? `${value}%` : value;

  let backgroundColor = color;

  if (loading) {
    backgroundColor = colorTheme("neutralL3");
  } else if (getColor) {
    backgroundColor = getColor(value);
  }

  const badgeElement = (
    <Badge
      style={{ ...getMargins({ m, mx, my, mt, mb, mr, ml }) }}
      $backgroundColor={backgroundColor}
      $loading={loading}
    >
      {loading ? " " : displayValue}
    </Badge>
  );

  if (tooltip) {
    return (
      <BadgeWrapper>
        <ToolTip text={tooltip}>{badgeElement}</ToolTip>
      </BadgeWrapper>
    );
  }

  return badgeElement;
};

export default MetricBadge;
